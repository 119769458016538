import React from "react";
import WikiLink from "./WikiLink";

const divStyle = {
    border: "1px solid white",
    padding: "10px",
    width: "fit-content"
};

const landingDivStyle = {
    border: "1px solid white",
    padding: "10px",
    width: "100%",
    maxWidth: "300px",
    marginLeft: "auto",
    marginRight: "auto"
};

const headerStyle = {
    marginBlockStart: "0px",
    marginBlockEnd: "0.25em"
};

const collapseStyle = {
    color: "grey",
    cursor: "pointer"
};

const catStyle = {
    marginBlockEnd: "0px"
};

const ulStyle = {
    marginTop: "5px"
};

/**
 * @typedef {Object} PageEntry
 * @prop {string} cat
 * @prop {string} page
 * @prop {PageEntry[] | null} nest
 */

/**
 * @param {string} cat
 * @param {string} page
 * @param {PageEntry[]} [nest]
 * @returns {PageEntry}
 */
const pageEntry = (cat, page, nest) => ({cat, page, nest: nest ?? null});

const classicPages = [
    pageEntry("classic", "Basics"),
    pageEntry("classic", "Scoring"),
    pageEntry("classic", "Difficulty"),
];

const junkatkPages = [
    pageEntry("junkatk", "Basics"),
    pageEntry("junkatk", "Abilities", [
        pageEntry("junkatk", "Junk Effects"),
    ]),
    pageEntry("junkatk", "Ability Meter"),
    pageEntry("junkatk", "Triggers", [
        pageEntry("junkatk", "Loadouts"),
        pageEntry("junkatk", "Random Meter"),
        pageEntry("junkatk", "High Combo"),
    ]),
    pageEntry("junkatk", "Modding", [
        pageEntry("junkatk", "Abilities by Type"),
        pageEntry("junkatk", "Priority Lists"),
        pageEntry("junkatk", "Junk Effect Names"),
        pageEntry("junkatk", "Color Names"),
        pageEntry("junkatk", "Junk Shapes"),
    ]),
];

const gauntletPages = [
    pageEntry("gauntlet", "Basics"),
];

const puzzlePages = [
    pageEntry("puzzle", "Basics"),
];

/**
 * @param {Object} props
 * @param {boolean} [props.startCollapsed]
 * @param {boolean} [props.landingStyle]
 */
export function WikiContents({
    startCollapsed = true,
    landingStyle = false,
})
{
    const [collapse, setCollapse] = React.useState(startCollapsed);

    /**
     * @param {PageEntry} pageEnt
     */
    const toWikiLink = React.useCallback(pageEnt => <li key={pageEnt.page}>
        <WikiLink category={pageEnt.cat} page={pageEnt.page} top={landingStyle}/>
        {pageEnt.nest && <ul>
            {pageEnt.nest.map(toWikiLink)}
        </ul>}
    </li>, [landingStyle]);

    return <div style={landingStyle ? landingDivStyle : divStyle}>
        <h2 style={headerStyle}>Wiki Contents</h2>
        <span
            style={collapseStyle}
            onClick={() => setCollapse(!collapse)}
        >
            {collapse ? "(show)" : "(collapse)"}
        </span>
        {!collapse &&
            <div>
                <h3 style={catStyle}>Classic Mode</h3>
                <ul style={ulStyle}>
                    {classicPages.map(toWikiLink)}
                </ul>
                <h3 style={catStyle}>Junk Attack Mode</h3>
                <ul style={ulStyle}>
                    {junkatkPages.map(toWikiLink)}
                </ul>
                <h3 style={catStyle}>Gauntlet Mode</h3>
                <ul style={ulStyle}>
                    {gauntletPages.map(toWikiLink)}
                </ul>
                <h3 style={catStyle}>Puzzle Mode</h3>
                <ul style={ulStyle}>
                    {puzzlePages.map(toWikiLink)}
                </ul>
            </div>
        }
    </div>;
}

const allPagesFlat = classicPages.concat(junkatkPages, gauntletPages, puzzlePages)
    .flatMap(ent => [
        pageEntry(ent.cat, ent.page),
        ...(ent.nest ?? [])
    ]);

function toIndex(cat, page)
{
    for (let i = 0; i < allPagesFlat.length; i++)
        if (allPagesFlat[i].cat === cat && allPagesFlat[i].page === page)
            return i;
    console.log(`WARNING: Could not find index of category ${cat}, page ${page}`);
    return -1;
}

function fromIndex(i)
{
    while (i < 0)
        i += allPagesFlat.length;
    while (i >= allPagesFlat.length)
        i -= allPagesFlat.length;
    return allPagesFlat[i];
}

/**
 * Returns a footer with previous/next topic buttons.
 */
export function WikiRingFooter({cat, page})
{
    const i = toIndex(cat, page);
    const prev = fromIndex(i - 1);
    const next = fromIndex(i + 1);

    return <div>
        <hr className="wikiRingSeparator"/>
        <div className="wikiRingFooter">
            <div style={{textAlign: "right"}}>
                <b>« Previous topic</b><br/>
                <WikiLink category={prev.cat} page={prev.page}
                    showCategory={true} top={true}/>
            </div>
            <div style={{textAlign: "left"}}>
                <b>Next topic »</b><br/>
                <WikiLink category={next.cat} page={next.page}
                    showCategory={true} top={true}/>
            </div>
        </div>
    </div>
}

/**
 * note to self:
 * to make sure all of the links in markdown are capitalized properly,
 * search for \(/wiki/.+/[a-z].*\)
 */
