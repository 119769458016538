import React from "react";

import "./footer.css";

export default function Footer()
{
    return <div id="siteFooter">
        <div>
            © Aidan Garvey, 2024.
        </div>
        <div>
            Polymino and the Polymino logo are unregistered trademarks of
            Aidan Garvey.
        </div>
        <div>
            All rights reserved.
        </div>
    </div>;
}
