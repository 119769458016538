export default function wikiCategoryName(cat)
{
    if (cat === "classic")
    {
        return "Classic Mode";
    }
    else if (cat === "junkatk")
    {
        return "Junk Attack";
    }
    else if (cat === "puzzle")
    {
        return "Puzzle Mode";
    }
    else if (cat === "gauntlet")
    {
        return "Gauntlet Mode";
    }
    else
    {
        return "(unknown)";
    }
}
