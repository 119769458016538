import React from "react";
import {useSearchParams} from "react-router-dom";

import "./wiki-search.css";

export default function WikiSearch()
{
    const [params] = useSearchParams();

    return <div id="search-bar">
        <div>
            <form action="/wiki/search">
                <input type="text" name="query" autoComplete="off" defaultValue={params.get("query") ?? ""}/>
                <input type="submit" value="Search"/>
            </form>
        </div>
    </div>;
}
