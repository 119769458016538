import React from "react";
import AbilityMeter from "./AbilityMeter";
import Abilities from "./LoadoutAbilities";

export default function Loadout({name, loadout})
{
    /**
     * @param {string} name 
     */
    const h3ID = (name) => {
        return name.replaceAll(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    };

    return <div className="loadoutDiv">
        <div>
            <h3 id={h3ID(name)}>{name}</h3>
            {loadout.summary &&
                <blockquote><p>{loadout.summary}</p></blockquote>
            }
            <Abilities loadout={loadout}/>
        </div>
        <AbilityMeter loadout={loadout}/>
    </div>;
}
