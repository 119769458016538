import "./abilities.css";

/**
 * @param {string} s
 */
function capitalize(s)
{
    return s[0].toUpperCase() + s.substring(1);
}

/**
 * @param {string} s
 */
function toTitleCase(s)
{
    return s.split(' ').map(capitalize).reduce((acc, x) => acc + " " + x);
}

/**
 * @param {string} s
 */
function highlight(s)
{
    const parts = s.split('_');
    const result = [parts.shift()];
    let key = 0;
    while (parts.length > 0)
    {
        result.push(
            <span className="abilityHighlight" key={key++}>
                {parts.shift()}
            </span>,
            parts.shift()
        );
    }
    return <>{result}</>;
}

function junkShapeName(s)
{
    s = s.toUpperCase();

    if (s === "BIG_L")
        return "4-by-3 Forwards 'L'";
    else if (s === "BIG_L_REV")
        return "4-by-3 Backwards 'L'";

    const sep = s.indexOf('X');
    const width = parseInt(s.substring("RECT_".length, sep));
    const height = parseInt(s.substring(sep + 1));

    if (width === 10 && height === 1)
    {
        return width + "-by-" + height + " Row";
    }
    else if (width === 1 && height === 1)
    {
        return "Single Block";
    }
    else if (width === height)
    {
        return width + "-by-" + height + " Square";
    }
    else
    {
        return width + "-by-" + height + " Rectangle";
    }
}

function bombShapeName(s)
{
    return capitalize(s);
}

function timedEffectName(s)
{
    switch (s)
    {
        case "grayout":
            return "Grayout";
        case "hide_queue":
            return "Hide queue";
        case "speed_up":
            return "Speed up";
        default:
            return "(UNKNOWN TIMED EFFECT)";
    }
}

// if an effect isn't in here, it's assumed that its internal name can be used
const EFFECT_NAMES = {
    "bomb_only": "bomb-only",
    "bomb_proof": "bomb-proof",
    "ink_bombs": "ink bombs",
    "exploding": "explosive",
    "gray_to_color": "gray to color",
    "all_gray": "all gray",
    "one_color": "one color"
};

function effectString(e)
{
    return "_" + toTitleCase(EFFECT_NAMES[e] ?? e) + "_";
}

const EFFECT_DESCS = {
    "bomb_only": "can only be destroyed by bombs",
    "bomb_proof": "can only be destroyed by projectiles",

    "ink_bombs": "covers nearby blocks in ink when shattered",
    "exploding": "detonates when shattered, destroying nearby blocks",
    "projectiles": "spawns multiple random projectiles when shattered",

    "fragment": "replaces each block with a single-block junk piece",

    "shatter": "shatters the junk piece",
    "gray_to_color": "changes gray junk pieces to random colors",
    "all_gray": "turns junk pieces gray",
    "one_color": "picks a random color, turns all affected pieces that color",
};

function effectDesc(e)
{
    if (e in EFFECT_DESCS)
    {
        return EFFECT_DESCS[e];
    }
    else
    {
        return "NO DESCRIPTION FOR " + e;
    }
}

function priorityString(p)
{
    if ("sort" in p)
        return compStr(p);
    else if ("filter" in p)
        return filterStr(p);
    else
        return <>{"(UNKNOWN PRIORITY TYPE)"}</>;
}

function compStr(p)
{
    return highlight((() => {
        if (p.sort === "altitude")
            return "Sort by _vertical board position,_ high to low";
        else if (p.sort === "size")
            return "Sort by _number of blocks,_ most to least";
        else
            return "(UNKNOWN SORT TYPE)";
    })());
}

function filterStr(p)
{
    function getDesc(p)
    {
        switch (p.filter)
        {
            case "is_shape":
                return "is shape: _" + junkShapeName(p.arg) + "_";
            case "not_shape":
                return "is not shape: _" + junkShapeName(p.arg) + "_";
            case "is_color":
                return "is color: _" + capitalize(p.arg) + "_";
            case "not_color":
                return "is not color: _" + capitalize(p.arg) + "_";
            case "is_queued":
                return "is _in queue_";
            case "not_queued":
                return "is _on board_";
            case "has_effect":
                return "has junk effect: " + effectString(p.arg);
            case "lacks_effect":
                return "does _not_ have junk effect: " + effectString(p.arg);
            case "has_positive":
                return "has _any positive junk effect_";
            case "has_negative":
                return "has _any negative junk effect_";
            case "lacks_positive":
                return "has _no positive junk effects_";
            case "lacks_negative":
                return "has _no negative junk effects_";
            default:
                return "(UNKNOWN FILTER TYPE)";
        }
    }
    const result = highlight(capitalize(getDesc(p)));
    if (p.strict)
    {
        return <>{result} <span className="strictFilter">(strict)</span></>;
    }
    else
    {
        return result;
    }
}

function targetDetails(t)
{
    return highlight((() => {
        if (t === "all")
            return "Target: _All players_";
        else if (t === "enemy")
            return "Target: _Opponents_";
        else
            return "Target: _" + capitalize(t) + "_";
    })());
}

function scopeDetails(s, amount)
{
    return highlight((() => {
        if (s === "board")
            return "Scope: _All pieces on board_";
        else if (s === "queue")
            return "Scope: _All pieces in queue_";
        else if (s === "all")
            return "Scope: _All junk pieces_";
        else if (s === "limited")
        {
            if (amount)
                return "Scope: _" + amount + " piece" + (amount === 1 ? "" : "s") + "_";
            else
                return "Scope: _All pieces which meet strict priorities_";
        }
        else
            return "(UNKNOWN SCOPE)";
    })());
}

function effectsDetails(effects)
{
    if (effects && effects.length > 0)
    {
        return <div>
            Junk effects:
            <ul>
                {
                    effects.map(e => <li>
                        {highlight(
                            `${toTitleCase(effectString(e))} (${effectDesc(e)})`
                        )}
                    </li>)
                }
            </ul>
        </div>;
    }
    else
    {
        return highlight("Junk effects: _None_");
    }
}

function prioritiesDetails(priorities)
{
    let currKey = 0;
    const keygen = () => (currKey++);
    return <div>
        Priorities:
        <ol>
            {priorities.map(p => <li key={keygen()}>{priorityString(p)}</li>)}
        </ol>
    </div>;
}

export function junkModDetails(a)
{
    const result = [
        targetDetails(a.target),
        <br/>,
        scopeDetails(a.scope, a.amount),
        <br/>,
        effectsDetails(a.effects)
    ];
    if (a.scope === "limited")
        // (don't push a <br/> because it's between lists)
        result.push(prioritiesDetails(a.priorities));
    return result;
}

export function junkTransferDetails(a)
{
    return [
        highlight(`Number of pieces: _${a.amount ?? 1}_`),
        <br/>,
        highlight(`Positions preserved: _${a.preservePos ? "Yes" : "No"}_`),
        <br/>,
        effectsDetails(a.effects),
        // no <br/> needed between lists
        prioritiesDetails(a.priorities)
    ];
}

export function bombAbilityDetails(a)
{
    return [
        highlight(`Bomb shape: _${toTitleCase(bombShapeName(a.shape))}_`)
    ];
}

export function transferBombDetails(a)
{
    return [
        highlight(`Bomb shape: _${toTitleCase(bombShapeName(a.shape))}_`),
        <br/>,
        highlight(`Positions preserved: _${a.preservePos ? "Yes" : "No"}_`),
        <br/>,
        effectsDetails(a.effects)
    ];
}

export function queueShapeModDetails(a)
{
    return [
        targetDetails(a.target),
        <br/>,
        highlight(`Junk shape: _${junkShapeName(a.shape)}_`),
        <br/>,
        effectsDetails(a.effects)
    ];
}

export function onShatterGlobalDetails(a)
{
    return [
        targetDetails(a.target),
        <br/>,
        highlight(`Effect: _${toTitleCase(effectString(a.effect))}_`),
        <br/>,
        highlight(`Duration: _${a.duration} seconds_`)
    ];
}

export function junkSpawnDetails(a)
{
    return [
        targetDetails(a.target),
        <br/>,
        highlight(`Junk type: _${junkShapeName(a.shape)}_`),
        <br/>,
        highlight(`Amount: _${a.amount}_`),
        <br/>,
        effectsDetails(a.effects)
    ];
}

export function timedAbilityDetails(a)
{
    return [
        targetDetails(a.target),
        <br/>,
        highlight(`Effect: _${toTitleCase(timedEffectName(a.effect))}_`),
        <br/>,
        highlight(`Duration: _${a.duration} seconds_`)
    ];
}

export function transferSpreeDetails(a)
{
    return [
        targetDetails(a.target),
        <br/>,
        highlight(`Duration: _${a.duration} seconds_`),
        <br/>,
        highlight(`Positions preserved: _${a.preservePos ? "Yes" : "No"}_`),
        <br/>,
        effectsDetails(a.effects)
    ];
}
