import React from "react";

import GIF1 from "./animations/0.6-1.gif";
import WEBP1 from "./animations/0.6-1-450x450.webp";
import GIF2 from "./animations/0.6-2.gif";
import WEBP2 from "./animations/0.6-2-450x450.webp";
import GIF3 from "./animations/0.6-3.gif";
import WEBP3 from "./animations/0.6-3-450x450.webp";

function Animation(webp, gif, alt)
{
    return <picture>
        <source srcSet={webp} type="image/webp"/>
        <img src={gif} alt={alt}/>
    </picture>;
}

export function Demo1()
{
    return Animation(WEBP1, GIF1, "Gameplay demo animation");
}

export function Demo2()
{
    return Animation(WEBP2, GIF2, "Gameplay demo animation");
}

export function Demo3()
{
    return Animation(WEBP3, GIF3, "Gameplay demo animation");
}
