import React from "react";

import "./abilities.css";

export default function AbilityMeter({loadout})
{
    if (!loadout) return null;

    const costs = loadout.levels ?? [400, 400, 400];
    const bombCost = loadout.bomb_cost ?? 200;
    const meterHeight = 300;
    const levels = Array.from(costs);
    const meterMax = levels[0] + levels[1] + levels[2];
    levels.unshift(bombCost);

    for (let i = 0; i < levels.length; i++)
    {
        levels[i] = levels[i] / meterMax * meterHeight;
    }

    return <div className="meterArea">
        {/*<div></div><div></div><div></div>*/}
        <div className="labelArea">
            {[3, 2, 1].map(i => <div
                key={i}
                className="indexLabel"
                style={{
                    height: levels[i] + "px"
                }}>
                    {i}
                </div>
            )}
            <div className="indexLabel" style={{
                position: "relative",
                bottom: levels[0] + "px"
            }}>
                BOMB
            </div>
        </div>
        <div className="meterGraphic">
            {[3, 2, 1].map(i => <div key={i} className={`meterSeg level${i}`} style={{
                height: levels[i] + "px"
            }}/>)}
            <div className="bombLevel" style={{
                position: "relative",
                bottom: levels[0] + "px"
            }}/>
        </div>
        <div className="labelArea">
            {[3, 2, 1].map(i => <div
                key={i}
                className="costLabel"
                style={{
                    height: levels[i] + "px"
                }}>
                    {costs[0] + (i >= 2 ? costs[1] : 0) + (i === 3 ? costs[2] : 0)}
                </div>
            )}
            <div className="costLabel" style={{
                position: "relative",
                bottom: levels[0] + "px"
            }}>
                {bombCost}
            </div>
        </div>
    </div>;
}
