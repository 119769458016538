import React from 'react';
import {WikiContents} from './WikiContents';

export default function Landing()
{
    return <div id="content-root">
        <div id="main-text">
            <h1>Polymino Wiki</h1>
            <p>
                This wiki contains detailed information about all aspects of the
                game. If you're new to Polymino, the best place to start is the
                introduction to <a href="/wiki/classic/Basics">Classic mode</a>.
            </p>
            <WikiContents startCollapsed={false} landingStyle={true}/>
        </div>
    </div>;
}
