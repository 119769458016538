import React from "react";
import {useSearchParams} from "react-router-dom"

import "./wiki.css";
import "./wiki-search.css";
import index from "./pages/search_index.json";
import wikiCategoryName from "./wikiCategoryName";

const ALIASES = {
    "abilities": "ability",
    "priorities": "priority",
    "effects": "effect",
};

// when multiple terms are given, limit to this many results (based on testing,
// results beyond 10 stop being useful)
const AND_MAX_RESULTS = 10;
// how if a result has each word at least once but the weight compared to other
// results is lower than 1%, treat it as not having all words
const AND_MIN_CONFIDENCE = 0.01;
// ignore terms shorter than three letters, because they aren't in the index
const TERM_MIN_LEN = 3;

export default function SearchResults()
{
    const [params] = useSearchParams();
    let results = null;
    if (!params.get("query"))
    {
        results = "Please enter some text to search for above.";
    }
    else
    {
        results = <div>
            <div style={{textAlign: "center"}}>
                <b>Results for <i>"{params.get("query")}"</i>:</b>
            </div>
            {getResults(params.get("query"))}
        </div>;
    }
    return <div id="content-root">
        <div id="main-text">
            <h1>Search Results</h1>
            {results}
        </div>
    </div>;
}

/**
 * @param {string} query note that even though the URL replaces spaces with '+',
 * the query object still has the spaces
 */
function getResults(query)
{
    // do similar processing on the input as the indexer
    const tokens = query
        // replace percent encoding with ascii
        .replaceAll(/%([0-9a-fA-F][0-9a-fA-F])/g, (_, p1) => {
            return String.fromCharCode(Number.parseInt(p1, 16));
        })
        // replace specific punctuation with spaces
        .replaceAll(/[_\-/]/g, ' ')
        // lowercase
        .toLowerCase()
        // split on whitespace
        .split(/\s+/);
    // put it all in a set of unique words, remove punctuation, replace aliases
    const queryWords = new Set(tokens
        .map(t => t.replaceAll(/\W+/g, ''))
        .filter(t => t.length >= TERM_MIN_LEN)
        .map(t => ALIASES[t] ?? t)
    )
    if (queryWords.size <= 0)
    {
        return "No valid search terms were entered. Please include at least " +
            "one word longer than two characters.";
    }
    const matches = {};
    // count how many title and text matches there are for each article
    for (const article in index)
    {
        const curr = {
            andScoreTitle: 1,
            andScore: 1,
            orScore: 0,
        }
        for (const q of queryWords)
        {
            const title = index[article].title[q] ?? 0;
            const words = index[article].words[q] ?? 0;
            curr.andScoreTitle *= title;
            curr.andScore *= title + words;
            curr.orScore += title + words;
        }
        matches[article] = curr;
    }

    const andScoreTotal = Object.keys(matches)
        .map(m => matches[m].andScore)
        .reduce((x, y) => x + y);

    // priority 1: all words are in the title
    // priority 2: all words are in the title and/or text
    // priority 3: at least one word is in the title and/or text
    const articles = Object.keys(matches)
        .filter(a => matches[a].orScore > 0)
        .sort((a, b) => {
            const aCon = matches[a].andScore / andScoreTotal;
            const bCon = matches[b].andScore / andScoreTotal;

            if (matches[a].andScoreTitle !== matches[b].andScoreTitle)
            {
                return matches[b].andScoreTitle - matches[a].andScoreTitle;
            }
            else if (matches[a].andScore !== matches[b].andScore
                && (aCon > AND_MIN_CONFIDENCE || bCon > AND_MIN_CONFIDENCE))
            {
                return matches[b].andScore - matches[a].andScore;
            }
            else
            {
                return matches[b].orScore - matches[a].orScore;
            }
        })
        .slice(0, queryWords.size > 1 ? AND_MAX_RESULTS : undefined);

    if (articles.length <= 0)
    {
        return "No results found.";
    }

    const capitalize = s => s[0].toUpperCase() + s.substring(1);
    const toTitleCase = s => {
        return s
            .split(' ')
            .map(s => s.length > 2 ? capitalize(s) : s)
            .reduce((acc, x) => acc + " " + x);
    };

    return <ul className="wikiSearchResults">{articles.map(a => {
        const cat = a.substring(0, a.indexOf('/'));
        const catName = wikiCategoryName(cat);
        const page = toTitleCase(a.substring(a.indexOf('/') + 1));
        return <li key={a}>
            <a href={cat + "/" + page}>
                <div className="resultTitle">
                    {catName}: {page}
                </div>
                <div className="resultReason">
                    {getExplanation(queryWords, matches[a], andScoreTotal)}
                </div>
            </a>
        </li>;
    })}</ul>;
}

function getExplanation(queryWords, matches, andScoreTotal)
{
    const confidence = matches.andScore / andScoreTotal;
    const conPercent = (confidence * 100).toFixed(1) + "%";
    if (matches.andScoreTitle > 0)
    {
        return "Matching title";
    }
    else if (queryWords.size === 1)
    {
        return "Text matches: " + matches.orScore;
    }
    else if (confidence > AND_MIN_CONFIDENCE)
    {
        return "All terms found (confidence: " + conPercent + ")";
    }
    else
    {
        return "At least one term found, " + matches.orScore + " matches total";
    }
}
