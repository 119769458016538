import React from "react";
import Navbar  from "./Navbar";
import Footer from "./Footer";
import Webring from "./Webring";

import OverheadPic from "./img/megamix_bts/overhead.jpg";
import DeskPic from "./img/megamix_bts/desk_cropped.jpg";
import OBSPic from "./img/megamix_bts/obs_2.jpg";

function MegamixYTVideo()
{
    return <iframe width="560" height="315"
        src="https://www.youtube-nocookie.com/embed/ggXSbXaEQNc?si=lscYymUNYLDWHx-Z"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen/>;
}

export default function News(props)
{
    const [show, setShow] = React.useState(false);

    return <div>
        <Navbar active="news"/>
        <div id="content-root">
            <div id="main-text">
                <h1>How to follow development</h1>
                <h2>Steam Community</h2>
                <p>
                    For a list of all patch notes, check out the <a
                        href="https://steamcommunity.com/app/2537550"
                    >
                        Steam Community page
                    </a> for Polymino!
                </p>
                <h2>Discord</h2>
                <p>
                    Polymino's <a href="https://discord.gg/TZgUwZzpZx">Discord
                    Server</a> features the latest updates about what's being
                    worked on, and is the best place to keep up-to-date with
                    development.
                    I love to discuss players' feedback and ideas there, many
                    of which quickly end up in the game!
                </p>
                <h2>YouTube</h2>
                <p>
                    While they usually aren't development updates,
                    I sometimes upload videos about Polymino to my <a
                        href="https://www.youtube.com/@welsh_"
                    >YouTube channel.</a> For entertaining content
                    related to the game, and any
                    big announcements which I want to reach new players,
                    YouTube is the place to go!
                </p>
                <h1>Behind the scenes: OST Megamix</h1>
                <p>
                    In version 0.6D, I completely overhauled Polymino's
                    soundtrack. As I'm sure you can imagine, the soundtrack
                    wasn't as high-priority as making the game... well...
                    playable, so there were only three songs. The old songs were
                    created with tracker software, mostly using samples of my
                    drum machines and synthesizers. I originally chose to use
                    tracker software because it allows songs to loop
                    seamlessly during gameplay, but I eventually realized that
                    it would be better to have multiple songs play back-to-back
                    during the game to prevent the music from getting too
                    repetitive.
                </p>
                <p>
                    Since the songs wouldn't have to loop seamlessly any more,
                    I wasn't restricted to using trackers. This allowed me to
                    record all of the new songs live, straight off my
                    instruments. So what's the next logical step after getting
                    so much practice performing those songs? Creating and
                    performing a live megamix, of course!
                </p>
                <div style={{
                    width: "fit-content",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}>
                    <MegamixYTVideo/>
                </div>
                <h2>Photos</h2>
                <p>
                    To capture the footage in the video, I used two cameras. The
                    first camera is an action cam which I used to get the overhead
                    perspective.
                </p>
                <img src={OverheadPic} style={{width: "100%"}}
                    alt="Overhead view of action cam overlooking the instruments"
                />
                <p>
                    In what can only be described as pure <strike>
                    jank</strike> resourcefulness, I attached the camera's mount
                    to the leg of an old tripod;
                    taped the tripod leg to a Guitar Hero
                    controller so it was attached to something flat and wouldn't
                    roll over; then put the whole thing on top of my PC.
                    Here's a picture of all my music gear which shows
                    more of the action cam rig:
                </p>
                <img src={DeskPic} style={{width: "100%"}}
                    alt="The drum machines and synthesizers used in the video, sitting on a desk"
                />
                <p>
                    A quick overview of the gear for anyone interested: <span
                        style={{
                            color: "gray",
                            cursor: "pointer"
                        }}
                        onClick={() => setShow(!show)}
                    >{show ? "(hide)" : "(show)"}</span>
                    {show && <ul>
                        <li>
                            <b>Roland T-8</b> (bottom left): hybrid drum machine and
                            bassline synthesizer. Its biggest appearance in this
                            set is the bassline in <i>The Groove.</i>
                        </li>
                        <li>
                            <b>Roland S-1</b> (above the T-8, green sides): by far the
                            most versatile synthesizer I own. Sadly, it's only
                            used for <i>The Groove</i> in this set; that's just the way
                            it played out when I was choosing which songs worked
                            well together for the mix.
                        </li>
                        <li>
                            <b>Korg Volca Bass</b> (top-left, silver): 
                            a big part of why I love this thing is its
                            sequencer,
                            which I make heavy use of during <i>Spyder</i> (fades
                            in at <a href="https://www.youtube.com/watch?v=ggXSbXaEQNc&t=520">
                            8:40</a>).
                            It also sounds incredible hooked up to
                            the overdrive pedal in the top-right (beige with
                            red dials).
                        </li>
                        <li>
                            <b>Behringer TD-3</b> (yellow):
                            yet another bassline synth,
                            but this one is a clone of the iconic TB-303,
                            the originator of Acid House. I used it in the
                            song <i>Caustic Soda,</i> so in this set it only
                            appears in the grand finale during the mashup of <i>
                            Caustic Soda, Downlow,</i> and <i>Ravebox.</i>
                        </li>
                        <li>
                            <b>Roland TR-6S</b> (above the TD-3): an awesome
                            drum machine, and the most
                            recent member of the crew.
                            I've been spending a lot of time exploring what
                            it can do since I got it, and Polymino's new 
                            soundtrack reflects that.
                            It's used in every song in this set, and it even
                            does a few songs solo.
                        </li>
                        <li>
                            <b>Yamaha MG06</b> (bottom right): this is my mixer,
                            which I bought at a pawn shop for an absolute steal
                            (it is missing a dial, but I never tweak the dials
                            on the mixer mid-set anyway).
                            I record the output by plugging it into the line-in
                            jack on my computer.
                        </li>
                    </ul>}
                </p>
                <p>
                    Back to the production: the camera I used for the
                    over-the-shoulder perspective is an old Sony Handycam which
                    shoots on 8mm cassettes.
                    Luckily for me, I can stream the video straight to OBS, and
                    sync it with the incoming audio from my mixer:
                </p>
                <img src={OBSPic} style={{width: "100%"}}
                    alt="Handycam aimed at the instruments, with a monitor in the background showing the camera feed in OBS"
                />
                <p>
                    You can also see more of my action cam rig above:
                    I taped it to a calculus textbook for extra height
                    and to weigh it down. <span style={{color: "gray"}}><i>
                    I knew those calculus classes would come in handy some day!
                    </i></span>
                </p>
                <p>
                    Thanks for reading, and I hope you enjoyed the set
                    <br/>
                    - Aidan
                </p>
            </div>
        </div>
        <Webring/>
        <Footer/>
    </div>;
}
