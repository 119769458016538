import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { WikiContents, WikiRingFooter } from "./WikiContents";
import PageContents from "./PageContents";
import LoadoutsList from "./LoadoutsList";
import Loadout from "./Loadout";
import wikiCategoryName from "./wikiCategoryName";
import bot_loadout from "./pages/gauntlet/data/bot_loadout.json";
import "./wiki.css";

export default function Page()
{
    const {wikiCat, wikiPage} = useParams();
    const pageSection = useLocation().hash;

    const catName = wikiCategoryName(wikiCat);

    const renderLoadouts = wikiCat === "junkatk"
        && wikiPage.toLowerCase() === "loadouts";
    const renderBotLoadout = wikiCat === "gauntlet"
        && wikiPage.toLowerCase() === "basics";

    const [content, setContent] = React.useState(null);
    const [error, setError] = React.useState(null);

    React.useEffect(() => {
        let cancel = false;
        // Resource name ends with ".txt" because the server will redirect any
        // url ending in ".html" to one with the file extension stripped.
        // BRUH
        const url = `${process.env.PUBLIC_URL}/wiki_pages/${wikiCat.toLowerCase()}/${wikiPage.toLowerCase()}.txt`;
        fetch(url).then(
            resp => resp.ok ?
                resp.text()
                : Promise.reject(resp.status + " " + resp.statusText)
        )
        .then(
            text => {
                if (!cancel)
                {
                    setContent(text);
                    setError(null);
                }
            },
            reason => {
                console.log("Failed to fetch wiki page: " + reason);
                if (!cancel)
                {
                    setContent(null);
                    setError(reason);
                }
            }
        );

        return () => {cancel = true;};
    }, [wikiCat, wikiPage]);

    let pageText = null;
    if (error !== null)
    {
        pageText = "Failed to get page. Reason: " + error;
    }
    else if (content === null)
    {
        pageText = "Loading...";
    }
    else
    {
        pageText = <div
            className="wikiPageBody"
        >
            <div dangerouslySetInnerHTML={{__html: content}}/>
            {renderLoadouts && <LoadoutsList/>}
            {renderBotLoadout && <div>
                <Loadout name={"Bot Loadout"} loadout={bot_loadout}/>
            </div>}
        </div>;
    }

    // Color speedTables, add GIF fallbacks for WEBPs
    React.useEffect(() => {
        colorSpeedTables();
        addGIFFallbacks();
        if (pageSection)
        {
            const sec = document.querySelector(pageSection);
            if (sec)
            {
                sec.scrollIntoView(true);
                const className = sec.getAttribute("class");
                sec.setAttribute("class", className ?
                    className + " activeSection"
                    :
                    "activeSection"
                );
            }
            else if (pageSection === "#top")
            {
                window.scrollTo(0, 0);
            }
        }
    });

    return <div id="content-root">
        <div id="main-text">
            <h1 className="pageTitle">{catName} - {wikiPage}</h1>
            <div className="contentsContainer">
                <PageContents startCollapsed={false}/>
                <WikiContents startCollapsed={true}/>
            </div>
            {pageText}
            <WikiRingFooter cat={wikiCat} page={wikiPage}/>
        </div>
    </div>;
}

function colorSpeedTables()
{
    for (const speedTable of document.querySelectorAll(".speedTable"))
    {
        const rows = speedTable.getElementsByTagName("tbody")[0].children;
        for (const tr of rows)
        {
            // Use textContent instead of iterating over the rows and using
            // the loop index. This allows the colors to be correct even if
            // the table skips speeds.
            const speed = parseInt(tr.children[0].textContent);
            if (speed >= 11 && speed <= 20)
            {
                let blue = (1.0 - ((speed - 10.0) / 5.0)) * 256.0;
                blue = Math.min(255, Math.max(0, blue));
                let green = (1.0 - ((speed - 15.0)) / 5.0) * 256.0;
                green = Math.min(255, Math.max(0, green));

                for (const td of tr.children)
                {
                    const oldStyle = td.getAttribute("style");
                    if (!oldStyle.startsWith("color"))
                    {
                        td.setAttribute("style",
                            `color:rgb(255,${green},${blue});${oldStyle}`);
                    }
                }
            }
        }
    }
}

// Take any figures with WEBP, convert the <img> into a <picture> with a GIF
// fallback
function addGIFFallbacks()
{
    /**
     * Search parent.childNodes for an Element node with the given tagName,
     * return it.
     * @param {HTMLElement} parent
     * @param {string} childTag
     * @returns {Element | null}
     */
    const getElementNode = (parent, childTag) => {
        for (const n of parent.childNodes)
        {
            if (n.nodeType === Node.ELEMENT_NODE
                && n.tagName.toLowerCase() === childTag)
            {
                return n;
            }
        }
        return null;
    };
    for (const fig of document.querySelectorAll("figure"))
    {
        // extract both components of figure
        const img = getElementNode(fig, "img");
        const caption = getElementNode(fig, "figcaption");

        // We may have already processed this figure, ignore it if so.
        // The image might not be a WEBP, in which case we shouldn't try
        // to create a fallback for it.
        if (img === null
            || !img.getAttribute("src").toLowerCase().endsWith("webp"))
        {
            continue;
        }

        // remove children from figure
        fig.removeChild(img);
        fig.removeChild(caption);

        // get paths to WEBP and GIF
        const webpSrc = img.getAttribute("src");
        const gifSrc = webpSrc.substring(0, webpSrc.lastIndexOf('.')) + ".gif";

        // create <source> tag to put in a <picture>, point it to the WEBP
        const source = document.createElement("source");
        source.setAttribute("srcSet", webpSrc);
        source.setAttribute("type", "image/webp");
        // make the <img> use the GIF as a fallback instead of the WEBP
        img.setAttribute("src", gifSrc);

        // create the <picture> with the new <source> and modified <img>
        const picture = document.createElement("picture");
        picture.appendChild(source);
        picture.appendChild(img);
        // put the <picture> and <figcaption> back, in the same order as before
        fig.appendChild(picture);
        fig.appendChild(caption);
    }
}
