import React from 'react';
import Navbar from "../Navbar";
import Footer from "../Footer";
import {Outlet} from 'react-router-dom';

import "./wiki.css";
import Webring from '../Webring';

export default function WikiContainer()
{
    return <div className="wikiContainer">
        <Navbar active="wiki"/>
        <Outlet/>
        <Webring/>
        <Footer/>
    </div>;
}
