import React from "react";
import loadouts from "./pages/junkatk/data/loadouts.json";
import Loadout from "./Loadout";

export default function LoadoutsList()
{
    return <div>{
        Object.entries(loadouts).map(([name, loadout]) => <Loadout
            name={name} loadout={loadout} key={name}
        />)
    }</div>;
}
