import Title from "./img/title.png";

import "./anim-logo.css";

import React from "react";

/**
 * Recreates the opening animation of Polymino. Uses masked images instead of a
 * subdivided model. Because the masked images don't look right at zoom levels
 * other than 100%, they disappear after their animation and get replaced by a
 * static version of the image.
 *
 * When the logo is clicked, it increments its key attribute, which causes it
 * to re-render, restarting the animation.
 */
export default function AnimatedLogo()
{
    const [restartCount, setRestartCount] = React.useState(0);
    function restartAnimation()
    {
        setRestartCount(restartCount + 1);
    }

    // need the div which will occupy the space
    return <div className="logoContainer" onClick={restartAnimation} key={restartCount}>
        <div className="logoMaskedUpper">
            <img src={Title} alt="" height="240" width="432"/>
        </div>
        <div className="logoMaskedLower">
            <img src={Title} alt="" height="240" width="432"/>
        </div>
        <div className="logoUnmasked">
            <img src={Title} alt="POLYMINO logo" height="240" width="432"/>
        </div>
    </div>;
}
